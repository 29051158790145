<template>
  <v-app>
    <slot></slot>
    <Snackbar />
  </v-app>
</template>

<script>
import event from '@/plugins/event-bus';

export default {};
</script>

<style lang="scss" scoped></style>
